import activity from './m-get-teacher-plan.vue';

export default activity;

export const componentRegistrationData = {
  name: 'Get Teacher Plan',
  icon: 'mdi-file-document-outline',
  description: 'Fill & get a teacher plan sent to your email',
  status: true,
  styling: {
    borderColor: '#eda1bf'
  },
  setupRequired: true,
  fields: [
    {
      roles: ['employer', 'participant'],
      name: 'm-get-teacher-plan',
      type: 'molecule',
      position: 0,
      preset: {},
      setup: {
        airtableLink: ''
      },
      value: {}
    },
    {
      roles: ['employer', 'participant'],
      name: 'm-save-adk-only',
      type: 'molecule',
      position: 1,
      preset: {},
      setup: {},
      value: {}
    }
  ]
};
